/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { DataPage } from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Pagination from "../Pagination";
import { PencilFill, Search, TrashFill } from "react-bootstrap-icons";
import { authHeader, handleResponse } from "helpers";
import { Button, Modal } from "react-bootstrap";

const returnUserPermission = (permission: string) => {
  if (permission === "user") {
    return "Usuário";
  } else if (permission === "admin") {
    return "Administrador";
  } else if (permission === "manager") {
    return "Gestor";
  } else {
    return "";
  }
};

const DataTable = () => {
  const [status, setStatus] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [toggleRefresh, setToggleRefresh] = useState(false);
  const [page, setPage] = useState<DataPage>({
    first: true,
    last: true,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  });
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const loadUsers = (activePage: number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${BASE_URL}/users?page=${activePage}&size=20&sort=name,asc`,
      requestOptions
    )
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      });
  };

  const searchUsers = (query: string, activePage: number) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(
      `${BASE_URL}/users/search?query=${query}&page=${activePage}&size=20&sort=name,asc`,
      requestOptions
    )
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      })
      .catch((error) => {
        setStatus(error.message);
        setTimeout(() => setStatus(null), 5000);
      });
  };

  const deleteUser = async (name: string, email: string, id: number) => {
    const result = window.confirm(
      `Você realmente deseja desabilitar o usuário ${name} com o email ${email}?`
    );
    if (result) {
      const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
      };

      fetch(`${BASE_URL}/users/${id}`, requestOptions)
        .then(handleResponse)
        .then(() => loadUsers(0))
        .catch((error) => {
          setStatus(error);
          setTimeout(() => setStatus(null), 10000);
        });
    }
  };

  const handleMassDelete = () => {
    setShowModal(false);
    const requestOptions = {
      method: "PUT",
      headers: {
        ...authHeader(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(selectedUsers),
    };
  
    fetch(`${BASE_URL}/users/inactive`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        if (response.message) {
          setStatus(response.message); 
          setTimeout(() => setStatus(null), 10000); 
        }
        setSelectedUsers([]);
        setToggleRefresh(!toggleRefresh);
      })
      .catch((error) => {
        setStatus(error.message);
        setTimeout(() => setStatus(null), 10000);
      });
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll && Array.isArray(page.content)) {
      setSelectedUsers(page.content.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const toggleSelectUser = (id: number) => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((userId) => userId !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    if (isSearching) {
      searchUsers(searchTerm, activePage);
    } else {
      loadUsers(activePage);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, isSearching, toggleRefresh]);

  const changePage = (index: number) => {
    setActivePage(index);
    setSelectedUsers([]);
    setSelectAll(false);
  };

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setIsSearching(false);
      setActivePage(0); 
    } else {
      setIsSearching(true);
      setActivePage(0);
    }

    setToggleRefresh(!toggleRefresh);
  };

  return (
    <>
      <div className="mt-4 mb-5">
        {status && <div className={"alert alert-danger"}>{status}</div>}

        <h5 className="font-weight-light text-secondary">Usuários</h5>
        <div
  className="d-flex justify-content-between align-items-center flex-wrap mt-3"
  style={{ flexWrap: "nowrap" }}
>

  <div
    className="input-group"
    style={{
      width: "300px",
      position: "relative",
      flexShrink: 0,
    }}
  >
    <input
      type="text"
      className="form-control"
      placeholder="Buscar usuário"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      style={{
        borderRadius: "25px",
        borderColor: "#ced4da",
        paddingRight: "35px",
        paddingLeft: "15px",
        boxShadow: "none",
        outline: "none",
      }}
    />
    <button
      className="btn"
      onClick={handleSearch}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        zIndex: 10,
        outline: "none",
        cursor: "pointer",
        boxShadow: "none",
      }}
    >
      <Search
        style={{
          width: "20px",
          height: "20px",
          color: "#6c757d",
          outline: "none",
          cursor: "pointer",
          boxShadow: "none",
        }}
      />
    </button>
  </div>

  <Pagination page={page} onPageChange={changePage} />

  <div className="d-flex align-items-center gap-3" style={{ marginLeft: "auto" }}>
    
    {selectedUsers.length > 1 && (
      <button
        className="btn btn-danger"
        onClick={() => setShowModal(true)}
        style={{ marginRight: "10px" }}
      >
        Desativar Selecionados
      </button>
    )}
    <a href="/users/new" className="btn btn-warning">
      Adicionar
    </a>
  </div>
</div>
        <table className="table table-hover shadow rounded bg-white text-center p-0 m-0">
          <thead>
            <tr>
            <th scope="col">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                />
              </th>
              <th scope="col">Usuário</th>
              <th scope="col">E-mail</th>
              <th scope="col">Permissão</th>
              <th scope="col">Empresa</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody className="table-borderless">
            {Array.isArray(page.content) && page.content?.length > 0 ? ( page.content?.map((item) => (
              <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(item.id)}
                      onChange={() => toggleSelectUser(item.id)}
                    />
                  </td>
                <th scope="row">{item.name}</th>
                <td>{item.email}</td>
                <td>{returnUserPermission(item.role)}</td>
                <td>{item.company.name}</td>
                <td>
                  <a
                    className="text-secondary"
                    href={`/users/edit/${item.id}`}
                  >
                    <PencilFill />
                  </a>{" "}
                  <a
                    className="text-secondary"
                    href="#"
                    onClick={() => deleteUser(item.name, item.email, item.id)}
                  >
                    <TrashFill />
                  </a>
                </td>
              </tr>
              ))
            ) : (
              <tr>
                <td >Nenhum usuário encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Tem certeza de que deseja desativar os usuários selecionados?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleMassDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DataTable;

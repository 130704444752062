/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "./../../utils/requests";
import Pagination from "../Pagination";
import {
  FileEarmarkTextFill,
  Check2,
  X,
  Download,
  PencilFill
} from "react-bootstrap-icons";
import { authHeader, handleResponse } from "./../../helpers";
import { Button, Modal } from "react-bootstrap";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import Input from "components/Form/Input";
import EditExpenseModal from "components/EditExpenseModal";
import { authenticationService } from "services";

interface FormData {
  feedback: string;
}

const returnStatus = (status: string) => {
  if (status === "PAGO") {
    return <p className="text-primary user-select-none p-0 m-0">Pago</p>;
  } else if (status === "APROVADO") {
    return <p className="text-success p-0 m-0">Aprovado</p>;
  } else if (status === "REPROVADO") {
    return <p className="text-danger p-0 m-0">Reprovado</p>;
  } else if (status === "PENDENTE_FINANCEIRO") {
    return <p className="text-warning p-0 m-0">Aguardando financeiro</p>;
  } else if (status === "PENDENTE_DIRETORIA") {
    return <p className="text-warning p-0 m-0">Aguardando diretoria</p>;
  } else if (status === "PENDENTE_GESTOR") {
    return <p className="text-warning p-0 m-0">Aguardando gestor</p>;
  } else {
    return <p className="text-warning p-0 m-0">Status desconhecido</p>;
  }
};

const SolicitationTable = ({
  updateExpenseValues,
  loadSolicitations,
  page,
  ...rest
}: any) => {
  const denyFormRef = useRef<FormHandles>(null);
  const approveFormRef = useRef<FormHandles>(null);
  const [status, setStatus] = useState(null);
  const [activePage, setActivePage] = useState(0);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [idSolicitation, setIdSolicitation] = useState(null);
  const [solicitationUser, setSolicitationUser] = useState(null);
  const [solicitationDescription, setSolicitationDescription] = useState(null);
  const [solicitationFeedback, setSolicitationFeedback] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [toggleRefresh, setToggleRefresh] = useState(false);

  const handleCloseRejectModal = () => setShowRejectModal(false);
  const handleCloseApproveModal = () => setShowApproveModal(false);
  const handleCloseDetailsModal = () => setShowDetailsModal(false);

  const handleShowRejectModal = () => setShowRejectModal(true);
  const handleShowApproveModal = () => setShowApproveModal(true);
  const handleShowDetailsModal = () => setShowDetailsModal(true);

  const role = authenticationService?.currentUserValue?.role;

  const handleEditClick = (data) => {
    setExpenseData(data);
    setIsModalEditOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalEditOpen(false);
    setExpenseData(null);
  };

  const changeSolicitationStatus = (
    id: number,
    feedback: string,
    status?: string
  ) => {
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        status,
        feedback,
      }),
    };

    fetch(`${BASE_URL}/solicitation/${id}/status`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        loadSolicitations(activePage);
        updateExpenseValues();
      })
      .catch((error) => {
        setStatus(error);
        setTimeout(() => setStatus(null), 10000);
      });
  };

  useEffect(() => {
    loadSolicitations(activePage);
  }, [activePage, toggleRefresh]);

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const denySolicitation: SubmitHandler<FormData> = async (data) => {
    try {
      const schema = Yup.object().shape({
        feedback: Yup.string()
          .min(3, "O feedback está muito curto")
          .required("O feedback é obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      denyFormRef.current?.setErrors({});

      changeSolicitationStatus(idSolicitation!, data.feedback, "REPROVADO");
      handleCloseRejectModal();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages: { [key in string]: string };
        errorMessages = {};
        err.inner.forEach((error: Yup.ValidationError) => {
          errorMessages[error.path!] = error.message;
        });
        denyFormRef.current?.setErrors(errorMessages);
      }
    }
  };

  const approveSolicitation: SubmitHandler<FormData> = async (data) => {
    changeSolicitationStatus(idSolicitation!, data.feedback);
    handleCloseApproveModal();
  };

  return (
    <>
      <div className="mt-5 mb-5">
        <div>
          <Modal show={showApproveModal} onHide={handleCloseApproveModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                Você realmente deseja aprovar a solicitação do usuário{" "}
                {solicitationUser}?
              </Modal.Title>
            </Modal.Header>
            <Form ref={approveFormRef} onSubmit={approveSolicitation}>
              <Modal.Body>
                <Input
                  label="Feedback"
                  name="feedback"
                  type="text"
                  placeholder="Insira o fedback da ação"
                  defaultValue={solicitationFeedback || ""}
                  divClass="col"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseApproveModal}>
                  Cancelar
                </Button>
                <Button variant="primary" type="submit">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showRejectModal} onHide={handleCloseRejectModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                Você realmente deseja negar a solicitação do usuário{" "}
                {solicitationUser}?
              </Modal.Title>
            </Modal.Header>
            <Form ref={denyFormRef} onSubmit={denySolicitation}>
              <Modal.Body>
                <Input
                  label="Feedback"
                  name="feedback"
                  type="text"
                  placeholder="Insira o fedback da ação"
                  defaultValue={solicitationFeedback || ""}
                  divClass="col"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseRejectModal}>
                  Cancelar
                </Button>
                <Button variant="danger" type="submit">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showDetailsModal} onHide={handleCloseDetailsModal}>
            <Modal.Header closeButton>
              <Modal.Title>Detalhes da Solicitação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>Descrição da Solicitação</h5>
              <p>{solicitationDescription}</p>
            </Modal.Body>
            <Modal.Body>
              <h5>Feedback do gestor</h5>
              <p>{solicitationFeedback || "Ainda sem feedback"}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={handleCloseDetailsModal}>
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>

          {isModalEditOpen && (
            <EditExpenseModal 
              isOpen={isModalEditOpen}
              onClose={handleCloseModal}
              initialData={expenseData}
              toggleRefresh={toggleRefresh}
              setToggleRefresh={setToggleRefresh}
            />
          )}
        </div>

        {status && <div className={"alert alert-danger"}>{status}</div>}
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="font-weight-light text-secondary">
            Solicitações realizadas
          </h5>
          <Pagination page={page} onPageChange={changePage} />
        </div>
        <table className="table table-hover shadow rounded bg-white text-center p-0 m-0">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Funcionário</th>
              <th scope="col">Gestor</th>
              <th scope="col">Centro de Custo</th>
              <th scope="col">Categoria</th>
              <th scope="col">Valor</th>
              <th scope="col">Status</th>
              <th scope="col">Detalhes</th>
              <th scope="col">Anexo</th>
              <th scope="col" colSpan={4}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody className="table">
            {page.content?.map((item) => (
              <tr key={item.id}>
                <th scope="row">
                  {new Date(item.date).toLocaleDateString("pt-BR", {
                    timeZone: "UTC",
                  })}
                </th>
                <td>{item.user.name}</td>
                <td>{item.user.manager?.name || "Sem Gestor"}</td>
                <td>{item.costCenter.description}</td>
                <td>{item.category.description}</td>
                <td>R$ {item.value.toFixed(2).toString().replace(".", ",")}</td>
                <td>{returnStatus(item.status)}</td>
                <td>
                  <a
                    className="text-sucess font-weight-bold"
                    href="#"
                    onClick={() => {
                      setSolicitationDescription(item.description);
                      setSolicitationFeedback(item.feedback);
                      handleShowDetailsModal();
                    }}
                  >
                    <FileEarmarkTextFill className="text-secondary font-weight-bold" />
                  </a>
                </td>
                <td>
                  <a href={`${BASE_URL}/solicitation/files/${item.attachment}`}>
                    <Download className="text-secondary font-weight-bold" />
                  </a>
                </td>
                <td colSpan={2}>
                  <a
                    className="text-sucess font-weight-bold"
                    href="#"
                    onClick={() => {
                      setIdSolicitation(item.id);
                      setSolicitationUser(item.user.name);
                      setSolicitationFeedback(item.feedback);
                      handleShowApproveModal();
                    }}
                  >
                    <Check2 />
                  </a>
                </td>
                {item.status === "PENDENTE_FINANCEIRO" && (role === 'admin' || role === 'root') && (
                  <td colSpan={2}>
                    <a
                      className="text-sucess font-weight-bold"
                      href="#"
                      onClick={() => {
                        handleEditClick(item)
                      }}
                    >
                      <PencilFill />
                    </a>
                  </td>
                )}
                <td colSpan={2}>
                  <a
                    className="text-danger font-weight-bold"
                    href="#"
                    onClick={() => {
                      setIdSolicitation(item.id);
                      setSolicitationUser(item.user.name);
                      setSolicitationFeedback(item.feedback);
                      handleShowRejectModal();
                    }}
                  >
                    <X />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SolicitationTable;

import { authHeader, handleResponse } from "helpers";
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Category, CostCenter } from "types/dataResponse"; 
import { BASE_URL } from "utils/requests";

interface FormData {
  id?: string;
  description: string;
  value: number;
  costCenter: CostCenter | null;
  category: Category | null;
  date: string;
  attachment: File | null;
}

const ExpenseModal = ({ isOpen, onClose, initialData, toggleRefresh, setToggleRefresh }: { isOpen: boolean, onClose: () => void, initialData: any, toggleRefresh: boolean, setToggleRefresh: any }) => {
  const [formData, setFormData] = useState<FormData>({
    id: "",
    description: "",
    value: 0,
    costCenter: null,
    category: null,
    date: "",
    attachment: null,
  });
  
  const [isSaving, setIsSaving] = useState(false);
  const [fileName, setFileName] = useState("Nenhum arquivo selecionado");
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setFileName(initialData?.attachment || "Nenhum arquivo selecionado");
    }

    const fetchCostCentersAndCategories = async () => {
      try {
            const requestOptions = {
              method: "GET",
              headers: authHeader(),
            };
        
            fetch(`${BASE_URL}/costcenter`, requestOptions)
              .then(handleResponse)
              .then((response) => {
                setCostCenters(response.data);
              });


        if (initialData && initialData?.costCenter) {
          fetchCategoriesByCostCenter(initialData.costCenter.id);
        }
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
        alert("Erro ao carregar cost centers");
      }
    };

    if (isOpen) {
      fetchCostCentersAndCategories();
    }
  }, [isOpen, initialData]);

  const fetchCategoriesByCostCenter = async (costCenterId: number) => {
    try {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/category/bycostcenter/` + costCenterId, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setCategories(response.data);
      });
    } catch (error) {
      console.error("Erro ao carregar categorias:", error);
      alert("Erro ao carregar categorias.");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === "file" && e.target instanceof HTMLInputElement && e.target.files) {
      const file = e.target.files[0]; 
      setFileName(file.name); 
      setFormData((prev) => ({
        ...prev,
        attachment: file
      }));
    } else if (name === "costCenter") {
      const selectedCostCenter = costCenters.find((center) => center.id === parseInt(value));
      setFormData((prev) => ({
        ...prev,
        costCenter: selectedCostCenter || null,
        category: null, 
      }));
      
      
      if (selectedCostCenter) {
        fetchCategoriesByCostCenter(selectedCostCenter.id);
      }
    } else if (name === "category") {
      const selectedCategory = categories.find((category) => category.id === parseInt(value));
      setFormData((prev) => ({
        ...prev,
        category: selectedCategory || null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
        const url = `${BASE_URL}/solicitation/${formData.id}`;

        const formDataToSend = new FormData();

        
        const jsonBlob = new Blob([JSON.stringify({
            id: formData.id,
            description: formData.description,
            value: formData.value,
            costCenter: formData.costCenter,
            category: formData.category,
            date: formData.date,
            attachment: formData.attachment ? formData.attachment.name : null
        })], { type: "application/json" });

        formDataToSend.append("solicitation", jsonBlob); 

        if (formData.attachment) {
            formDataToSend.append("file", formData.attachment);
        }

        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: authHeader().Authorization, 
            },
            body: formDataToSend,
        });

        if (!response.ok) {
            throw new Error("Erro ao salvar os dados");
        }

        onClose();
    } catch (error) {
        alert("Ocorreu um erro ao salvar os dados. Tente novamente.");
    } finally {
        setIsSaving(false);
        setToggleRefresh(!toggleRefresh);
    }
};

  const handleFileClick = () => {
    const fileInput = document.getElementById("file") as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{formData.id ? "Editar Despesa" : "Criar Despesa"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label htmlFor="description">Descrição</label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="value">Valor</label>
            <input
              type="number"
              className="form-control"
              id="value"
              name="value"
              value={formData.value}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="costCenter">Centro de Custo</label>
            <select
              className="form-control"
              id="costCenter"
              name="costCenter"
              value={formData.costCenter?.id || ""}
              onChange={handleChange}
            >
              <option value="">Selecione o Centro de Custo</option>
              {costCenters.map((costCenter) => (
                <option key={costCenter.id} value={costCenter.id}>
                  {costCenter.costCenter} | {costCenter.description}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="category">Categoria</label>
            <select
              className="form-control"
              id="category"
              name="category"
              value={formData.category?.id || ""}
              onChange={handleChange}
              disabled={formData.costCenter === null}
            >
              <option value="">Selecione a Categoria</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.codeExpense} | {category.description}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="date">Data da Despesa</label>
            <input
              type="date"
              className="form-control"
              id="date"
              name="date"
              value={formData.date?.substring(0, 10)}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="file">Arquivo: {fileName}</label>
            <p></p>
            <input
              type="file"
              className="form-control"
              id="file"
              name="file"
              style={{ display: "none" }} 
              onChange={handleChange}
            />
            <Button variant="secondary" onClick={handleFileClick}>
              Selecionar Arquivo
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} disabled={isSaving}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={isSaving}>
          {isSaving ? "Salvando..." : "Salvar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExpenseModal;

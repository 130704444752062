/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import SolicitationTable from "components/SolicitationTable";
import SolicitationDonutChart from "components/SolicitationDonutChart";
import { authHeader, handleResponse } from "helpers";
import { useEffect, useRef, useState } from "react";
import {
  Category,
  CostCenter,
  TotalSolicitation,
  TotalValueSolicitation,
  User,
} from "types/dataResponse";
import { BASE_URL } from "utils/requests";
import Select from "components/Form/Select";
import { DataPage } from "./../../types/dataResponse";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Input from "components/Form/Input";

type ChartData = {
  labels: string[];
  series: number[];
  colors: string[];
};

interface FormData {
  userId: string | null;
  status: string | null;
  dateStart: string | null;
  dateEnd: string | null;
  costCenterId: string | null;
  categoryId: string | null;
}

const SolicitationDashboard = () => {
  const [page, setPage] = useState<DataPage>({
    first: true,
    last: true,
    number: 0,
    totalElements: 0,
    totalPages: 0,
  });

  const [users, setUsers] = useState<User[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  const formRef = useRef<FormHandles>(null);

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<string | null>(null);
  const [statusSelected, setStatusSelected] = useState<string | null>(null);
  const [dateStartSelected, setDateStartSelected] = useState<string | null>(
    null
  );
  const [dateEndSelected, setDateEndSelected] = useState<string | null>(null);
  const [costCenterSelected, setCostCenterSelected] = useState<string | null>(null);
  const [categorySelected, setCategorySelected] = useState<string | null>(null);

  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    series: [],
    colors: [],
  });

  const [expenseValues, setExpenseValues] = useState<TotalValueSolicitation>({
    totalApproved: 0,
    totalPaid: 0,
    totalPending: 0,
    totalReject: 0,
  });

  const loadSolicitations = (
    activePage: number,
    clean: boolean = false,
    userId: string | null = null,
    status: string | null = null,
    dateStart: string | null = null,
    dateEnd: string | null = null,
    costCenterId: string | null = null,
    categoryId: string | null = null
  ) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (isFilter && !clean && !(userId || status || dateStart || dateEnd || costCenterId || categoryId)) {
      userId = userSelected;
      status = statusSelected;
      dateStart = dateStartSelected;
      dateEnd = dateEndSelected;
      costCenterId = costCenterSelected;
      categoryId = categorySelected;
    }

    const urlSolicitation = new URL(`${BASE_URL}/solicitation`);

    urlSolicitation.searchParams.append("page", activePage.toString());
    urlSolicitation.searchParams.append("size", "20");
    urlSolicitation.searchParams.append("sort", "date,desc");

    if (userId) {
      urlSolicitation.searchParams.append("userId", userId);
    }

    if (status) {
      urlSolicitation.searchParams.append("status", status);
    }

    if (dateStart) {
      urlSolicitation.searchParams.append("initialDate", dateStart);
    }

    if (dateEnd) {
      urlSolicitation.searchParams.append("endDate", dateEnd);
    }

    if (costCenterId) {
      urlSolicitation.searchParams.append("costCenterId", costCenterId);
    }

    if (categoryId) {
      urlSolicitation.searchParams.append("categoryId", categoryId);
    }

    fetch(urlSolicitation.toString(), requestOptions)
      .then(handleResponse)
      .then((response) => {
        setPage(response.data);
      });
  };

  const sumTotalValue = (expenses: TotalValueSolicitation) => {
    return (
      expenses.totalApproved +
      expenses.totalPaid +
      expenses.totalPending +
      expenses.totalReject
    );
  };

  const updateExpenseValues = (
    clean: boolean = false,
    userId: string | null = null,
    status: string | null = null,
    dateStart: string | null = null,
    dateEnd: string | null = null,
    costCenterId: string | null = null,
    categoryId: string | null = null
  ) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    if (isFilter && !clean && !(userId || status || dateStart || dateEnd || costCenterId || categoryId)) {
      userId = userSelected;
      status = statusSelected;
      dateStart = dateStartSelected;
      dateEnd = dateEndSelected;
      costCenterId = costCenterSelected;
      categoryId = categorySelected;
    }

    const urlTotalValue = new URL(`${BASE_URL}/solicitation/totalvalue`);
    const urlValue = new URL(`${BASE_URL}/solicitation/total`);

    if (userId) {
      urlTotalValue.searchParams.append("userId", userId);
      urlValue.searchParams.append("userId", userId);
    }

    if (status) {
      urlTotalValue.searchParams.append("status", status);
      urlValue.searchParams.append("status", status);
    }

    if (dateStart) {
      urlTotalValue.searchParams.append("initialDate", dateStart);
      urlValue.searchParams.append("initialDate", dateStart);
    }

    if (dateEnd) {
      urlTotalValue.searchParams.append("endDate", dateEnd);
      urlValue.searchParams.append("endDate", dateEnd);
    }

    if (costCenterId) {
      urlTotalValue.searchParams.append("costCenterId", costCenterId);
      urlValue.searchParams.append("costCenterId", costCenterId);
    }

    if (categoryId) {
      urlTotalValue.searchParams.append("categoryId", categoryId);
      urlValue.searchParams.append("categoryId", categoryId);
    }

    fetch(urlTotalValue.toString(), requestOptions)
      .then(handleResponse)
      .then((response) => {
        setExpenseValues(response.data as TotalValueSolicitation);
      });

    fetch(urlValue.toString(), requestOptions)
      .then(handleResponse)
      .then((response) => {
        const data = response.data as TotalSolicitation;
        const myLabels = ["Pagos", "Pendentes", "Aprovados", "Rejeitados"];
        const mySeries = [data.paid, data.pending, data.approved, data.reject];
        const myColors = ["#0275d8", "#f0ad4e", "#5cb85c", "#d9534f"];

        setChartData({ labels: myLabels, series: mySeries, colors: myColors });
      });
  };

  const handleSubmit: SubmitHandler<FormData> = async (data) => {

    loadSolicitations(
      0,
      false,
      data.userId,
      data.status,
      data.dateStart,
      data.dateEnd,
      data.costCenterId,
      data?.categoryId
    );
    updateExpenseValues(
      false,
      data.userId,
      data.status,
      data.dateStart,
      data.dateEnd,
      data.costCenterId,
      data?.categoryId
    );

    setIsFilter(true);
    setUserSelected(data.userId || null);
    setStatusSelected(data.status || null);
    setDateStartSelected(data.dateStart || null);
    setDateEndSelected(data.dateEnd || null);
    setCostCenterSelected(data.costCenterId || null);
    setCategorySelected(data.categoryId || null);
  };

  const cleanFilter = async () => {
    formRef!.current!.reset();

    setIsFilter(false);
    setUserSelected(null);
    setStatusSelected(null);
    setDateStartSelected(null);
    setDateEndSelected(null);
    setCostCenterSelected(null);
    setCategorySelected(null);

    loadSolicitations(0, true);
    updateExpenseValues(true);
  };

  const downloadExport = async () => {
    const userId = userSelected;
    const status = statusSelected;
    const dateStart = dateStartSelected;
    const dateEnd = dateEndSelected;
    const costCenterId = costCenterSelected;
    const categoryId = categorySelected;

    const url = new URL(`${BASE_URL}/solicitation/export`);

    if (userId) {
      url.searchParams.append("userId", userId);
    }

    if (status) {
      url.searchParams.append("status", status);
    }

    if (dateStart) {
      url.searchParams.append("initialDate", dateStart);
    }

    if (dateEnd) {
      url.searchParams.append("endDate", dateEnd);
    }

    if (costCenterId) {
      url.searchParams.append("costCenterId", costCenterId);
    }

    if (categoryId) {
      url.searchParams.append("categoryId", categoryId);
    }
    
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(url.toString(), requestOptions)
      .then((res) => res.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "solicitações.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  useEffect(() => {
    updateExpenseValues();

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    fetch(`${BASE_URL}/users/picker`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        setUsers(response.data);
      });

    fetch(`${BASE_URL}/costcenter`, requestOptions)
          .then(handleResponse)
          .then((response) => {
            setCostCenters(response.data);
          });

  }, []);

  const loadCategories = (e) => {
      const idCostCenter = e?.value;
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
  
      fetch(`${BASE_URL}/category/bycostcenter/` + idCostCenter, requestOptions)
        .then(handleResponse)
        .then((response) => {
          setCategories(response.data);
        });
    };

  return (
    <div className="container">
      <div className="jumbotron shadow rounded bg-light p-1 text-center">
        <h5 className="display-5 font-weight-light text-danger">
          Página administrativa
        </h5>

        <Form ref={formRef} onSubmit={handleSubmit} className="mt-3 mb-3 pb-0">
          <h6 className="pb-3">Filtro de Solicitações</h6>
          <div className="form-group row">
            <Select
              label="Funcionário"
              name="userId"
              placeholder="Selecione..."
              divClass="col"
              options={users.map((user) => ({
                value: user.id,
                label: user.name,
              }))}
            />

            <Select
              label="Status"
              name="status"
              placeholder="Selecione..."
              divClass="col"
              options={[
                { status: "PENDENTE_GESTOR", name: "Aguardando gestor" },
                {
                  status: "PENDENTE_FINANCEIRO",
                  name: "Aguardando financeiro",
                },
                { status: "PENDENTE_DIRETORIA", name: "Aguardando diretoria" },
                { status: "APROVADO", name: "Aprovado" },
                { status: "REPROVADO", name: "Reprovado" },
                { status: "PAGO", name: "Pago" },
              ].map((status) => ({
                value: status.status,
                label: status.name,
              }))}
            />

            <Select
              label="Centro de custo"
              name="costCenterId"
              placeholder="Selecione..."
              divClass="col"
              options={costCenters.map((costCenter) => ({
                value: costCenter.id,
                label: costCenter.description,
              }))}
              onChange={(e) => {
                setCostCenterSelected(e?.value)
                loadCategories(e)
              }}
            />
            </div>
            <div className="form-group row">
            <Select
              isDisabled={costCenterSelected === null}
              label="Categoria"
              name="categoryId"
              placeholder="Selecione..."
              divClass="col"
              options={categories.map((category) => ({
                value: category.id,
                label: category.codeExpense + " | " + category.description,
              }))}
            />

            <Input
              label="Data de início"
              divClass="col"
              name="dateStart"
              type="date"
            />

            <Input
              label="Data de término"
              divClass="col"
              name="dateEnd"
              type="date"
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <button type="submit" className="btn btn-primary">
              Filtrar
            </button>
            <a
              href="#"
              className="btn btn-outline-warning text-reset mx-3"
              onClick={cleanFilter}
            >
              Limpar filtro
            </a>
            <a
              href="#"
              className="btn btn-outline-info text-reset"
              onClick={downloadExport}
            >
              Exportar
            </a>
          </div>
        </Form>
      </div>

      <div className="card-columns">
        <div className="card text-center shadow rounded">
          <div className="card-header bg-white text-secondary font-weight-light">
            Total de Despesas
          </div>
          <h3 className="card-title font-weight-light text-secondary pt-3 pb-0">
            R${" "}
            {sumTotalValue(expenseValues)
              .toFixed(2)
              .toString()
              .replace(".", ",")}
          </h3>
          <div
            className="card-body bg-white m-0 p-0"
            style={{ height: "178px" }}
          >
            <SolicitationDonutChart chartData={chartData} className="m-0 p-0" />
          </div>
        </div>

        <div className="card text-center shadow rounded font-weight-light">
          <div className="card-header bg-white text-secondary">Pagos</div>
          <div className="card-body bg-white">
            <h1 className="card-text font-weight-light text-primary">
              R${" "}
              {expenseValues.totalPaid.toFixed(2).toString().replace(".", ",")}
            </h1>
          </div>
        </div>
        <div className="card text-center shadow rounded font-weight-light">
          <div className="card-header bg-white text-secondary">Aprovados</div>
          <div className="card-body bg-white">
            <h1 className="card-text font-weight-light text-success">
              R${" "}
              {expenseValues.totalApproved
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </h1>
          </div>
        </div>
        <div className="card text-center shadow rounded font-weight-light">
          <div className="card-header bg-white text-secondary">Pendentes</div>
          <div className="card-body bg-white">
            <h1 className="card-text font-weight-light text-warning">
              R${" "}
              {expenseValues.totalPending
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </h1>
          </div>
        </div>
        <div className="card text-center shadow rounded font-weight-light">
          <div className="card-header bg-white text-secondary">Rejeitados</div>
          <div className="card-body bg-white">
            <h1 className="card-text font-weight-light text-danger">
              R${" "}
              {expenseValues.totalReject
                .toFixed(2)
                .toString()
                .replace(".", ",")}
            </h1>
          </div>
        </div>
      </div>

      {
        <SolicitationTable
          updateExpenseValues={updateExpenseValues}
          loadSolicitations={loadSolicitations}
          page={page}
        />
      }
    </div>
  );
};

export default SolicitationDashboard;
